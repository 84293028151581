import { css } from '@emotion/react';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Modal } from '../../../components/modal';
import { auth, hasRegisteredCredentials } from '../../../utils/webauthn';
import { useContext } from 'react';
import { CompanyContext } from '../../../context/company.context';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../../context/lang.context';
import Button from '../../button/button';
const ONLY_NUMBERS_REGEX = /^\d*$/;

type Props = {
  get: (text: string) => string;
  onSubmit: (pin: string, isWebAuthn: boolean) => void;
  token: string;
  invalidPIN: boolean;
  onChangePIN: (value: boolean) => void;
  onResetPIN:() => void;
  resettingPinState: 'idle' | 'failed' | 'loading' | ''
};

export const EnterPINForm = ({ onSubmit, token, invalidPIN, onChangePIN, onResetPIN, resettingPinState }: Props) => {
  const { t } = useContext(LangContext);
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          LOADING {
            TITLE
          }
          SITE {
            LOCK {
              FORM {
                STEP_02 {
                  LABEL
                }
              }
            }
          }
          ENTER_PIN {
            BIOMETRICS_PROMPT
            BIOMETRICS_NO
            BIOMETRICS_YES
            BUTTON
            RESET_PIN_BUTTON
            RESET_ERROR_MSG
            ERROR_VERIFICATION {
              TITLE_1
              TITLE_2
            }
          }
        }
      }
    }
  `);
  const { getPublicURL, clientId } = useContext(CompanyContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showValue, setShowValue] = useState(false);
  const [webAuthnModal, setWebAuthnModal] = useState(false);


  const formik = useFormik({
    initialValues: { pin: '', isWebAuthn: false },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      pin: Yup.string(),
      isWebAuthn: Yup.boolean(),
    }),

    onSubmit(values: { pin: string; isWebAuthn: boolean }, { setSubmitting }) {
      if (!formik.values.pin && !formik.values.isWebAuthn) {
        inputRef.current?.focus();
        setSubmitting(false);
        return;
      }
      setSubmitting(true);
      onSubmit(values.pin, values.isWebAuthn);
    },
  });

  const focusInput = () => inputRef.current?.focus();

  const authByBiometrics = () => {
    auth(token)
      .then(() => {
        console.log('Web Authentication succeeded.');
        // we set a mock pin all along, so as to avoid any further validation
        formik.setFieldValue('pin', '1111', false);
        formik.setFieldValue('isWebAuthn', true, false);
        formik.submitForm();
      })
      .catch(error => {
        // @todo: UI feedback: notify user about any failures
        console.error('Web Authentication failed.', error);
      });
  };



  useEffect(focusInput, []);

  useEffect(() => {
    if (hasRegisteredCredentials(clientId)) {
      setWebAuthnModal(true);
    }
  }, []);

  return (
    <section className="content">
      <label className="block text-gray-800 mb-3">{t(data).SITE.LOCK.FORM.STEP_02.LABEL}</label>
      <div className="relative">
        <input
          ref={inputRef}
          value={formik.values.pin}
          onChange={event => {
            formik.setFieldValue('pin', event.target.value);
            onChangePIN(false);
            formik.setSubmitting(false);
          }}
          onKeyPress={e => !ONLY_NUMBERS_REGEX.test(e.key) && e.preventDefault()}
          onKeyUp={event => event.key === 'Enter' && !formik.isSubmitting && formik.submitForm()}
          className={['block w-full h-12 border px-2 mb-2 rounded-sm', invalidPIN && 'border-red'].join(' ')}
          maxLength={4}
          type={showValue ? 'text' : 'password'}
          css={css`
            &::-webkit-credentials-auto-fill-button {
              visibility: hidden;
            }
          `}
          inputMode="numeric"
        />
        <div
          className="absolute right-0 top-1/2 transform -translate-y-1/2 w-10 p-2 text-center cursor-pointer"
          onClick={() => {
            setShowValue(!showValue);
            focusInput();
          }}
        >
          {showValue ? <i className="icon-eye-open" /> : <i className="icon-eye-stroke" />}
        </div>
      </div>
      <div className={!invalidPIN ? 'flex hidden' : 'flex'}>
        <img src={getPublicURL(`/theme/assets/images/status/wrong.svg`)} className="mr-2" />
        <p className="text-red text-sm">{t(data).ENTER_PIN.ERROR_VERIFICATION.TITLE_2}</p>
      </div>
      {formik.errors.pin && (
        <p className="text-red text-sm">
          <span className="icon-exclamation-triangle mr-1" />
          {formik.errors.pin}
        </p>
      )}
      <Button
        color={'primary'}
        type="submit"
        onClick={formik.submitForm}
        disabled={formik.isSubmitting || resettingPinState === 'loading'}
        className={`${invalidPIN ? 'mt-1' : 'mt-8'}`}
      >
        {formik.isSubmitting ? t(data).LOADING.TITLE : t(data).ENTER_PIN.BUTTON}
      </Button>
      <Button
        color={'secondary'}
        type="button"
        onClick={() => onResetPIN()}
        disabled={formik.isSubmitting || resettingPinState === 'loading'}
        className="mt-1"
      >
        {resettingPinState === 'loading' ? t(data).LOADING.TITLE : t(data).ENTER_PIN?.RESET_PIN_BUTTON}
      </Button>
      {resettingPinState === 'failed' && (
        <p className={`text-center text-red text-sm mt-2`}>
          <span className="icon-exclamation-triangle mr-1" />
          {t(data).ENTER_PIN?.RESET_ERROR_MSG}
        </p>
      )}
      <Modal show={webAuthnModal} hidden={() => setWebAuthnModal(false)}>
        <div className="p-4">
          <h3 className="text-lg text-center mb-8">{t(data).ENTER_PIN.BIOMETRICS_PROMPT}</h3>
          <div>
            <Button
              color={'primary'}
              type="button"
              onClick={() => authByBiometrics()}
              className="block py-4 leading-none transition-colors duration-200"
            >
              {t(data).ENTER_PIN.BIOMETRICS_YES}
            </Button>
            <Button
              color={'secondary'}
              type="button"
              onClick={() => setWebAuthnModal(false)}
              className="block py-4 leading-none transition-colors duration-200"
            >
              {t(data).ENTER_PIN.BIOMETRICS_NO}
            </Button>
          </div>
        </div>
      </Modal>
    </section>
  );
};
