import Layout from '../../responsive/layout';
import Topbar from '../../responsive/topbar';
import communicationService from '../../../services/communication';
import { returnWhatsapp } from '../../../utils/browser';
import React, { useState, useEffect, useContext } from 'react';
import { LoadingComponent } from '../../loading';
import { useStatus, StatusProps } from '../../../hooks/pin.hook';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../../context/lang.context';
import Button from '../../button/button';

export const EnterPINStatus = ({ get, statusType, intent, communicationToken }: StatusProps) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          RETURN_WHATSAPP
        }
      }
    }
  `);
  const [communicationTokenSent, setCommunicationTokenSent] = useState(false);
  const { status, setStatus } = useStatus({ get, statusType, intent, communicationToken });

  useEffect(() => {
    // please note the communication token only works hand in hand with an existing intent
    if (communicationToken && intent) {
      communicationService
        .postCommunicationToken({ token: communicationToken, text: intent })
        .catch(() => {
          // if communication token fails, we present generic "unavailable" screen;
          // @todo: we might want to improve this with a more specific message
          setStatus('unavailable');
        })
        .finally(() => setCommunicationTokenSent(true));
    }
  }, [communicationToken]);

  // wait until communication token is succesfully sent before displaying status message
  // (if there's no intent, there's nothing to wait here...)
  if (communicationToken && intent && !communicationTokenSent) {
    return <LoadingComponent get={get} />;
  }

  return (
    <Layout>
      <Topbar />
      <section className="content text-center">
        <h1
          className={`heading mx-auto mb-6 ${status.error ? 'text-red' : 'text-primary-900'}`}
          style={{ maxWidth: 280 }}
          dangerouslySetInnerHTML={{ __html: status.title }}
        />
        <img className="mx-auto mb-10" src={status.image} alt="frame" />
        {status.paragraph && <p className="text-gray mb-10">{status.paragraph}</p>}
        <Button color={'primary'} onClick={() => returnWhatsapp({ message: status.whatsapp || '' })}>
          {t(data).RETURN_WHATSAPP}
        </Button>
      </section>
    </Layout>
  );
};
