import React from 'react';

import { ModalPropsComponent } from './models';

import { ModalWrapper, ModalContent } from './styles';

const Modal = ({ show, hidden, children, classNameContent }: ModalPropsComponent) => {
  if (!show) return null;

  return (
    <ModalWrapper>
      <ModalContent className={`max-w-xl ${classNameContent}`}>
        <span className="icon-close" onClick={hidden} />
        {children}
      </ModalContent>
    </ModalWrapper>
  );
};

export { Modal };
