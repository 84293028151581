import React, { useState, useContext, useEffect } from 'react';
import { EnterPINForm } from '../../components/forms/pin/enter-pin-form';
import { EnterPINStatus } from '../../components/forms/pin/enter-pin-status';
import { CompanyContext } from '../../context/company.context';
import Layout from '../../components/responsive/layout';
import Portrait from '../../components/responsive/portrait';

import appService from '../../services/endpoints';
import creditCardService from '../../services/endpoints';
import pages from '../../constants/pages.json';

import { CreditcardContext } from '../../context/creditcard.context';
import { navigateToCustomPage, navigateToFailed, navigateToSuccess } from '../../utils/navigate';
import { useRecaptcha } from '../../hooks/recaptcha.hook';

import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../context/lang.context';
import { removeNodes } from '../../utils/dom';

type PINStatusType = '' | 'success' | 'locked' | 'unverified' | 'unavailable';

const EnterPINPage = ({ get, getToken }: PageProps) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          ENTER_PIN {
            TITLE
          }
        }
      }
    }
  `);
  const { getPublicURL } = useContext(CompanyContext);
  const [status, setStatus] = useState<PINStatusType>('');
  const [statusIntent, setStatusIntent] = useState('');
  const [communicationToken, setCommunicationToken] = useState();
  const { setLoading } = useContext(CreditcardContext);
  const { getRecaptchaToken } = useRecaptcha();
  const [invalidPIN, setInvalidPIN] = useState(false);
  const [resettingPinState, setResettingPinState] = useState<'idle' | 'failed' | 'loading' | ''>('idle');
  const token = getToken();

  const handleSubmit = async (pin: string, isWebAuthn: boolean) => {
    let state;
    const recaptchaToken = await getRecaptchaToken();
    appService
      .patchEnterPin(token, pin, recaptchaToken, isWebAuthn)
      .then(({ action, url, intent, feedback, communication_token }) => {
        if (action === 'redirect') {
          navigateToCustomPage(`/${url}`)?.();
        } else if (action === 'paid') {
          state = { from: pages.SUCCESS_PAGE.PAID, response: feedback };
          return navigateToSuccess({ state, callback: () => setLoading?.(false) })?.();
        } else if (action === 'custom_error') {
          let from = '';
          if (feedback.error_code === 'FGS1309') {
            from = pages.FAILED_PAGE.CVV;
          } else if (['FGS1303', 'FGS2000'].includes(feedback.error_code)) {
            from = pages.FAILED_PAGE.ADDED_NOT_PAID;
          } else if (feedback.error_code === 'FGS1901') {
            return setInvalidPIN(true); // es necesario el return para reintentar pin
          } else if (feedback.error_code === 'FGS1902') {
            return setStatus('locked'); // es necesario el return para redirigir a pantalla bloqueada
          } else {
            from = pages.FAILED_PAGE.NOT_ADDED_NOT_PAID;
          }
          state = {
            lastFourNumbersCard: feedback.card.last_four_digits,
            typeCard: feedback.card.payment_method_name,
            from,
          };

          return navigateToFailed({ state, callback: () => setLoading?.(false) })?.();
        } else {
          setStatus('success');
          setStatusIntent(intent);
          // @todo: instead of piling up more logic here, we could probably abstract out
          // this communication token logic into a separate provider, or something
          setCommunicationToken(communication_token);
        }
      })
      .catch(error => {
        if (error === 'FGS1902') {
          setStatus('locked');
        } else if (error === 'FGS1901') {
          setInvalidPIN(true);
        } else {
          setStatus('unavailable');
          throw 'error';
        }
      });
  };

  const handleChangePin = (invalidPIN: boolean) => setInvalidPIN(invalidPIN);

  const handleResetPin = async () => {
    setResettingPinState('loading');
    try {
      await creditCardService.resetPin(token);
      navigateToCustomPage(`${pages.LIVENESS}?${token}`, { replace: true })?.();
    } catch (error) {
      setResettingPinState('failed');
      console.error(error);
    }
  };

  useEffect(() => {
    return () => {
     removeNodes(['#grecaptcha', '.grecaptcha-badge']);
    };
  }, []);

  if (status) {
    return (
      <EnterPINStatus get={get} statusType={status} intent={statusIntent} communicationToken={communicationToken} />
    );
  }

  return (
    <Layout>
      <Portrait>
        <section className="content relative text-center">
          <h1
            className="heading text-white mx-auto"
            style={{ maxWidth: 240 }}
            dangerouslySetInnerHTML={{ __html: t(data).ENTER_PIN.TITLE }}
          />
          <img src={getPublicURL(`/theme/assets/images/responsive/pin/card.svg`)} alt="PIN" className="w-full my-5" />
        </section>
      </Portrait>

      <EnterPINForm
        get={get}
        onSubmit={handleSubmit}
        token={token}
        invalidPIN={invalidPIN}
        onChangePIN={handleChangePin}
        onResetPIN={handleResetPin}
        resettingPinState={resettingPinState}
      />
    </Layout>
  );
};

export default EnterPINPage;
