import styled from '@emotion/styled';
import tw from 'twin.macro';

const ModalWrapper = styled.div`
  ${tw`flex inset-0 justify-center items-center absolute`}
  background-color: rgba(0,0,0,0.7);
`;

const ModalContent = styled.div`
  ${tw`relative bg-white p-2 rounded w-10/12`}

  span {
    transform: translateY(-200%);
    right: 0.5rem;
    ${tw`absolute top-0 text-base text-white font-bold`}
  }
`;

export { ModalWrapper, ModalContent };
